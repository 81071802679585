import { createStore } from "vuex";
import AuthStore from "@/store/authModule";
import FilterModule from "@/store/filterModule";
import ToastNotification from "@/store/toastNotification";
import complexRosterModule from "@/store/complexRosterModule";

export default createStore({
  modules: {
    AuthStore,
    FilterModule,
    ToastNotification,
    ComplexRoster: complexRosterModule,
  },
});
