export default class AuthStore {
  public static storageKey = "token";
  public static regOrgTokenKey = "regOrgToken";

  public static getToken() {
    return localStorage.getItem(AuthStore.storageKey);
  }

  public static setToken(token: string) {
    localStorage.setItem(AuthStore.storageKey, token);
  }

  // Reg org token
  public static getRegOrgToken() {
    return localStorage.getItem(AuthStore.regOrgTokenKey);
  }

  public static setRegOrgToken(token: string) {
    localStorage.setItem(AuthStore.regOrgTokenKey, token);
  }

  public static removeRegOrgToken(): void {
    localStorage.removeItem(AuthStore.regOrgTokenKey);
  }

  public static removeToken(): void {
    localStorage.removeItem(AuthStore.storageKey);
  }

  public static isSignedIn(): boolean {
    return !!AuthStore.getToken();
  }

  public static setReleaseVersion(version: string) {
    localStorage.setItem("ReleaseVersion", version);
  }

  public static getReleaseVersion() {
    return localStorage.getItem("ReleaseVersion");
  }

  public static getTokenData() {
    const token = AuthStore.getToken();
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    }

    return {};
  }
}
