export default {
  state: {
    toasts: [],
    toastId: 0,
    autoHideDuration: 30000,
    autoHide: true,
  },
  getters: {
    getToasts: (state: any) => state.toasts,
  },
  mutations: {
    addToast(state: any, toast: any) {
      state.toasts.unshift(toast);
    },
    removeToast(state: any, toastId: number) {
      state.toasts = state.toasts.filter((toast: any) => toast.id !== toastId);
    },
    reset(state: any) {
      state.toasts = [];
      state.toastId = 0;
    },
  },
  actions: {
    showToast(
      { commit, state }: any,
      { message, type, title = "Attention", autoHide = true }: any
    ) {
      function timestamp() {
        const dateObject = new Date();
        const day = String(dateObject.getDate()).padStart(2, "0");
        const month = dateObject.toLocaleString("default", { month: "short" });
        const year = dateObject.getFullYear();
        const time = dateObject.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });

        return `${day} ${month} ${year}, ${time}`;
      }

      const newToast = {
        id: state.toastId++,
        message,
        type,
        title,
        timestamp: timestamp(),
      };

      commit("addToast", newToast);

      // Auto hide after autoHideDuration sec
      if (autoHide && state.autoHide) {
        setTimeout(() => {
          commit("removeToast", newToast.id);
        }, state.autoHideDuration);
      }
    },

    hideToast({ commit }: any, toastId: number) {
      commit("removeToast", toastId);
    },

    reset({ commit }: any) {
      commit("reset");
    },
  },
};
