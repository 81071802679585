<template>
  <div :class="`card shadow-sm py-2 ${margin}`">
    <div
      :class="`row d-flex align-items-center justify-content-center justify-content-sm-between ${padding}`"
    >
      <!--        Details-->
      <div :class="`col-md-${detailsCol} col-sm-12`">
        <div v-if="avatar" class="d-flex align-items-center">
          <!--          Avatar-->
          <div class="ml-2 mr-3">
            <slot name="avatar">
              <div
                class="d-flex avatar-card-container justify-content-center align-items-center"
              >
                <font-awesome-icon class="avatar-card" icon="user" />
              </div>
            </slot>
          </div>
          <!--          Info-->
          <div class="d-block word-warp">
            <!--                    Details-->
            <slot name="details"></slot>
          </div>
        </div>
        <div v-else class="px-3">
          <slot name="details"></slot>
        </div>
      </div>

      <!--        actions-->
      <div :class="`col-md-${actionCol} col-sm-12 mr-2 my-2 my-md-0`">
        <div class="d-flex justify-content-center justify-content-md-end">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardRow",
  props: {
    margin: {
      type: String,
      default: "mb-2",
    },
    padding: {
      type: String,
      default: "py-2",
    },
    actionCol: {
      type: String,
      default: "auto",
    },
    detailsCol: {
      type: String,
      default: "auto",
    },
    avatar: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
