import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import AuthStore from "@/store/auth-store";
import store from "@/store";

function isAdmin(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const tokenData = AuthStore.getTokenData();
  const userRoles = tokenData.Roles;

  if (userRoles == "Admin" || userRoles == "Org Admin") {
    next();
  } else {
    next({
      path: "/account/login",
      query: { redirect: to.fullPath },
    });
  }
}

function isAdminOrManager(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const tokenData = AuthStore.getTokenData();
  const userRoles = tokenData.Roles;

  if (
    userRoles == "Admin" ||
    userRoles == "Org Admin" ||
    userRoles == "Manager"
  ) {
    next();
  } else {
    next({
      path: "/account/login",
      query: { redirect: to.fullPath },
    });
  }
}

function isEmployee(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const tokenData = AuthStore.getTokenData();
  const userRoles = tokenData.Roles;

  if (userRoles == "Employee") {
    next();
  } else {
    next({
      path: "/admin/home",
    });
  }
}
function isSelfOrAdminOrManager(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const tokenData = AuthStore.getTokenData();
  const userRoles = tokenData.Roles;
  const authId = tokenData.EmployeeId;
  const requestId = to.params.id || "0";

  if (
    userRoles == "Admin" ||
    userRoles == "Org Admin" ||
    userRoles == "Manager" ||
    authId == requestId
  ) {
    next();
  } else {
    next({
      path: from.path,
    });
  }
}

const routes: Array<RouteRecordRaw> = [
  { path: "/", redirect: "/admin/home" },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/views/shared/404.vue"),
  },
  {
    name: "login",
    path: "/account/login",
    component: () => import("@/views/account/login/Login.vue"),
  },
  {
    name: "searchEmail",
    path: "/account/forgotPassword",
    component: () => import("@/views/account/forgot-password/SearchEmail.vue"),
  },
  {
    name: "chnagePassword",
    path: "/resetPassword",
    component: () =>
      import("@/views/account/forgot-password/ChangePassword.vue"),
  },
  {
    name: "/account",
    path: "/account",
    component: () => import("@/account/AccountLayout.vue"),
    children: [
      {
        name: "register",
        path: "register",
        component: () => import("@/views/account/register/RegisterCompany.vue"),
      },
      {
        name: "registerOrgAdmin",
        path: "registerOrgAdmin",
        component: () =>
          import("@/views/account/register/RegisterOrgAdmin.vue"),
        props: true,
      },
      {
        name: "continueEmail",
        path: "continueEmail",
        component: () => import("@/views/account/register/ContinueEmail.vue"),
        props: true,
      },
      {
        name: "userRegistration",
        path: "userRegistration",
        component: () =>
          import("@/views/account/register/UserRegistration.vue"),
      },
      {
        name: "success",
        path: "success/:status",
        component: () => import("@/views/shared/SuccessPage.vue"),
      },
    ],
  },

  // Guarded Route
  {
    path: "/admin",
    component: () => import("@/layout/Layout.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "home",
        name: "Dashboard",
        component: () => import("../views/dashboard/Dashboard.vue"),
      },
      {
        path: "employee/upload",
        name: "Bulk Upload Employee",
        component: () => import("../views/employees/EmployeeUpload.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "employee",
        name: "Employees",
        component: () => import("../views/employees/EmployeeList.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "employee/new",
        name: "New Employee",
        component: () => import("@/views/employees/NewEmployee.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "employee/edit/:id",
        name: "Edit Employee",
        redirect: { name: "Employee Details" },
        component: () => import("@/views/employees/EditEmployee.vue"),
        beforeEnter: [isSelfOrAdminOrManager],
        children: [
          {
            path: "details",
            name: "Employee Details",
            component: () => import("@/views/employees/EmployeeDetails.vue"),
            beforeEnter: [isSelfOrAdminOrManager],
          },
          {
            path: "goals",
            name: "Employee Goals",
            component: () =>
              import("@/views/employees/goals/EmployeeGoals.vue"),
            beforeEnter: [isSelfOrAdminOrManager],
          },
          {
            path: "notes",
            name: "Employee Notes",
            component: () =>
              import("@/views/employees/notes/EmployeeNotes.vue"),
            beforeEnter: [isSelfOrAdminOrManager],
          },
          {
            path: "emergency-contact",
            name: "Employee Emergency Contact",
            component: () =>
              import(
                "@/views/employees/emergency-contact/EmployeeEmergencyContact.vue"
              ),
            beforeEnter: [isSelfOrAdminOrManager],
          },
        ],
      },
      {
        path: "emergencycontact",
        name: "Emergency Contact",
        component: () =>
          import("@/views/emergency-contact/EmergencyContactList.vue"),
        props: true,
      },
      {
        path: "emergencycontact/new",
        name: "New Emergency Contact",
        component: () =>
          import("@/views/emergency-contact/NewEmergencyContact.vue"),
        props: true,
      },
      {
        path: "emergencycontact/edit",
        name: "Edit Emergency Contact",
        component: () =>
          import("@/views/emergency-contact/EditEmergencyContact.vue"),
        props: true,
      },
      {
        path: "leaves/:id/:name",
        name: "Leaves",
        component: () => import("@/views/leaves/BookLeavesList.vue"),
      },

      {
        path: "departments",
        name: "Department",
        component: () => import("@/views/department/DepartmentList.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "department/new",
        name: "New Department",
        component: () => import("@/views/department/NewDepartment.vue"),
        beforeEnter: isAdmin,
      },
      {
        path: "department/edit/:departmentId",
        name: "Edit Department",
        component: () => import("@/views/department/EditDepartment.vue"),
        beforeEnter: isAdmin,
      },
      {
        path: "notes/:id",
        name: "Notes",
        component: () => import("@/views/notes/Notes.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "notes/new/:id",
        name: "New Note",
        component: () => import("@/views/notes/NewNote.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "notes/edit/:id",
        name: "Edit Note",
        component: () => import("@/views/notes/EditNote.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "performance/review/:id",
        name: "Review",
        component: () => import("@/views/performance/InviteReview.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "goals/:id",
        name: "Goals",
        component: () => import("@/views/goals/Goals.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "goals/new/:id",
        name: "New Goal",
        component: () => import("@/views/goals/NewGoals.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "goals/edit/:id",
        name: "Edit Goal",
        component: () => import("@/views/goals/EditGoal.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "finder",
        name: "Finder",
        component: () => import("@/views/employees/Directory.vue"),
        beforeEnter: isEmployee,
      },
      {
        path: "documents/manage",
        name: "Manage Documents",
        component: () => import("@/views/documents/DocumentList.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "document/new",
        name: "New Document",
        component: () => import("@/views/documents/NewDocument.vue"),
        beforeEnter: isAdminOrManager,
      },
      {
        path: "mydocuments",
        name: "My Documents",
        component: () => import("@/views/documents/MyDocuments.vue"),
      },

      // Settings
      {
        path: "settings",
        name: "Settings",
        component: () => import("@/views/settings/Settings.vue"),
      },

      // Rosters
      {
        path: "rosters",
        name: "Rosters",
        component: () => import("@/views/roster/Rosters.vue"),
      },
      // Feedback
      {
        path: "feedback",
        name: "Feedback",
        component: () => import("@/views/feedback/feedback.vue"),
      },
      //Document Employees
      {
        path: "documents/employees/:id",
        name: "Document Employees",
        component: () => import("@/views/documents/DocumentEmployees.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (to.matched.some((record: any) => record.meta.requiresAuth)) {
      const tokenData = AuthStore.getTokenData();
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      const t = new Date(1970, 0, 1);
      t.setSeconds(tokenData.exp);
      const currentTime = Date.parse(new Date().toString());
      const tokenTime = Date.parse(t.toString());

      if (!AuthStore.isSignedIn() || tokenTime < currentTime) {
        AuthStore.removeToken();
        store.commit("removeOrgDetails");
        store.commit("removeAuthUser");
        store.commit("removeVersion");
        next({
          path: "/account/login",
        });
      } else {
        next();
      }
    } else {
      next(); // make sure to always call next()!
    }
  }
);

export default router;
