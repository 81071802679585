<template>
  <div :class="`custom-input${size ? '-' : ''}${size}`">
    <div class="icon-left" v-if="icon">
      <font-awesome-icon class="input-icon text-primary" :icon="icon" />
    </div>
    <slot class="" name="input"></slot>
  </div>
</template>

<script>
export default {
  name: "InputFiled",
  props: {
    icon: String,
    size: {
      type: String,
      required: false,
      default: "lg",
    },
  },
};
</script>

<style scoped></style>
