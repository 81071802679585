function parseData(key: string) {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key) || "");
  }
  return null;
}
function setData(payload: any, key: string) {
  if (payload) {
    localStorage.setItem(key, JSON.stringify(payload));
  }
}

export default {
  state: {
    isAuth: !!localStorage.getItem("token"),
    authUser: parseData("authUser"),
    version: parseData("version"),
    orgDetails: parseData("orgDetails"),
  },
  getters: {
    isAuth(state: any) {
      return state.isAuth;
    },
    authUser(state: any) {
      return state.authUser;
    },
    version(state: any) {
      return state.version;
    },
    orgDetails(state: any) {
      return state.orgDetails;
    },
  },
  mutations: {
    removeToken(state: any) {
      state.isAuth = false;
    },

    login(state: any) {
      state.isAuth = true;
    },
    authUser(state: any, payload: any) {
      state.authUser = payload;
      setData(payload, "authUser");
    },
    removeAuthUser(state: any) {
      localStorage.removeItem("authUser");
      state.authUser = "";
    },
    version(state: any, payload: any) {
      state.version = payload;
      setData(payload, "version");
    },

    removeVersion(state: any) {
      localStorage.removeItem("version");
      state.version = "";
    },
    orgDetails(state: any, payload: any) {
      state.orgDetails = payload;
      setData(payload, "orgDetails");
    },

    removeOrgDetails(state: any) {
      localStorage.removeItem("orgDetails");
      state.orgDetails = "";
    },
  },
  actions: {
    removeToken(context: any, payload: any) {
      context.commit("removeToken");
    },

    login(context: any, payload: any) {
      context.commit("login");
    },
  },
};
