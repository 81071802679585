import { IAssignee, IDay, IRoster, IShift } from "@/types/Roster";

export default {
  state: {
    rosters: [
      {
        name: "Test Complex Roster",
        id: 0,
        departmentId: 0,
        organisationId: 0,
        departmentName: "",
        lastDate: new Date().toDateString(),
        days: [
          {
            date: new Date().toDateString(),
            isHoliday: false,
            shifts: [] as IShift[],
          },
        ] as IDay[],
      },
    ] as IRoster[],
    shiftKey: 0,
    roster: {} as IRoster,
    rosterDayBackup: {} as IDay,
    rosterDay: {} as IDay,
    editingRosterIndex: -1,
    editingDayIndex: -1,
    editingCellIndex: -1,
    dayEditFlag: false,
    isCreatingOrCloningFlag: -1,
  },
  getters: {
    rosters(state: any) {
      return state.rosters;
    },
    roster(state: any) {
      return state.roster;
    },
    rosterDay(state: any) {
      return state.rosterDay;
    },
    editingRosterIndex(state: any) {
      return state.editingRosterIndex;
    },
    editingDayIndex(state: any) {
      return state.editingDayIndex;
    },
    editingCellIndex(state: any) {
      return state.editingCellIndex;
    },
    dayEditFlag(state: any) {
      return state.dayEditFlag;
    },
    isCreatingOrCloningFlag(state: any) {
      return state.isCreatingOrCloningFlag;
    },
  },
  mutations: {
    /**
     * Set the 'isAuth' state to 'false'.
     * @param {any} state - The Vuex state object.
     */
    setRosters(state: any): void {
      state.isAuth = false;
    },

    /**
     * Set the 'roster' state to the provided payload.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - The data to set as the 'roster'.
     */
    setRoster(state: any, payload: any): void {
      state.roster = payload;
    },

    /**
     * Set the 'rosterDay' state to the provided payload.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - The data to set as the 'rosterDay'.
     */
    setRosterDay(state: any, payload: any): void {
      state.rosterDay = payload;
    },

    /**
     * Reset various flags and states to their initial values.
     * @param {any} state - The Vuex state object.
     * @param resetCreatingOrCloningFlag
     */
    resetFlags(state: any, resetCreatingOrCloningFlag = false): void {
      state.editingRosterIndex = -1;
      state.editingDayIndex = -1;
      state.editingCellIndex = -1;
      state.dayEditFlag = false;
      state.rosterDayBackup = {};
      if (resetCreatingOrCloningFlag || state.isCreatingOrCloningFlag == -1) {
        state.rosterDay = {};
      }

      if (resetCreatingOrCloningFlag) {
        state.isCreatingOrCloningFlag = -1;
      }
    },

    /**
     * Start editing a cell by setting relevant index.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - An object containing editing details.
     */
    editCell(state: any, payload: any): void {
      state.editingRosterIndex = payload.editingRosterIndex;
      state.editingDayIndex = payload.editingDayIndex;
      state.editingCellIndex = payload.editingCellIndex;
      state.dayEditFlag = payload.dayEditFlag;
    },

    /**
     * Create a backup of the 'rosterDay' that will be edited, in case the user needs to reset the change.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - An object containing editing details.
     */
    createBackup(state: any, payload: any): void {
      state.rosterDay =
        state.rosters[payload.editingRosterIndex].days[payload.editingDayIndex];
      state.rosterDayBackup = JSON.parse(
        JSON.stringify(
          state.rosters[payload.editingRosterIndex].days[
            payload.editingDayIndex
          ]
        )
      );
    },

    /**
     * Add an assignee to a cell's 'assignedEmployees' array and assign the statuses.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - The assignee to add to the cell.
     */
    addAssignee(state: any, payload: any): void {
      const assignees =
        state.rosters[state.editingRosterIndex].days[state.editingDayIndex]
          .shifts[state.editingCellIndex].assignedEmployees;
      const index = assignees.findIndex(
        (employee: any) => employee.employeeId === payload.id
      );

      /**
       * Check if the assignee already exist.
       * Index -1 means it doesn't exist, so push the assignee (with status=1) to cell.
       * Index 0 or more means, it already exists in the cell (old assignee).
       * Old assignee can be in 0 status or in delete status (status=2).
       * If status 0 the no need to push, this will create duplicate assignee.
       * If status 2, change it to 0. Meaning the assignee is back from delete status to no change (status=0)
       * The ui filter outs the employee with status=2 while rendering.
       */

      if (index == -1) {
        const data = {
          employeeId: payload.id,
          firstName: payload.firstName,
          lastName: payload.lastName,
          status: 1,
        };
        assignees.push(data);
      } else {
        if (assignees[index].status == 2) {
          assignees[index].status = 0;
        }
      }
    },

    /**
     * Remove an assignee from a cell's 'assignedEmployees' array.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - The ID of the assignee to remove.
     */
    removeAssignee(state: any, payload: any): void {
      const assignees =
        state.rosters[state.editingRosterIndex].days[state.editingDayIndex]
          .shifts[state.editingCellIndex].assignedEmployees;
      const index = assignees.findIndex(
        (employee: any) => employee.employeeId === payload
      );

      /**
       * Check the assignee's 'status' and either remove or set it to '2'.
       * Status 0 means old assignee, so to delete (ui will filter it out by status) it set status to 2.
       * Backend will track it and remove it.
       * Status 1 means assignee was newly added, so remove it from the payload. The backend doesn't need to know.
       */
      if (assignees[index].status === 0) {
        assignees[index].status = 2;
      } else {
        assignees.splice(index, 1);
      }
    },

    /**
     * Add a new cell to a specific day in a roster.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - An object containing roster and cell data.
     */
    addCell(state: any, payload: any): void {
      state.rosters[payload.rosterIndex].days[payload.dayIndex].shifts.push(
        payload.data
      );
    },

    /**
     * Clone a day and add it to the specified roster.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - An object containing roster and day data.
     */
    cloneDay(state: any, payload: any): void {
      state.rosters[payload.rosterIndex].days.push(payload.data);
      state.rosters[payload.rosterIndex].lastDate = payload.data.date;
      state.rosterDay = state.rosters[payload.rosterIndex].days.slice(-1);
      state.isCreatingOrCloningFlag = payload.rosterIndex;
    },

    /**
     * Add a new day to the specified roster.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - An object containing roster and day data.
     */
    addDay(state: any, payload: any): void {
      state.rosters[payload.rosterIndex].days.push(payload.data);
      state.rosters[payload.rosterIndex].lastDate = payload.data.date;
      state.rosterDay = state.rosters[payload.rosterIndex].days.slice(-1);
      state.isCreatingOrCloningFlag = payload.rosterIndex;
    },

    /**
     * Cancel creating or cloning day
     * @param {any} state - The Vuex state object.
     * @param {any} payload - An object containing roster and day data.
     */
    cancelCreatingDay(state: any, payload: any): void {
      state.rosters[payload.rosterIndex].days.splice(-1);
      state.isCreatingOrCloningFlag = -1;
      console.log("Cancelled creating");
    },

    /**
     * Add a new day to the specified roster.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - An object containing roster and day data.
     */
    saveDay(state: any, payload: any): void {
      state.rosters[payload.rosterIndex].days.push(payload.data);
      state.rosters[payload.rosterIndex].lastDate = payload.data.date;
      state.rosterDay = state.rosters[payload.rosterIndex].days.slice(-1);
      state.isCreatingOrCloningFlag = payload.rosterIndex;
    },

    /**
     * Update a cell's start and end time in a roster.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - An object containing new start and end times.
     */
    addShiftTime(state: any, payload: any): void {
      state.rosters[state.editingRosterIndex].days[
        state.editingDayIndex
      ].shifts[state.editingCellIndex].startTime = payload.startTime;
      state.rosters[state.editingRosterIndex].days[
        state.editingDayIndex
      ].shifts[state.editingCellIndex].endTime = payload.endTime;
    },

    /**
     * Remove a cell from a specific day in a roster.
     * @param {any} state - The Vuex state object.
     * @param {any} payload - An object specifying the roster, day, and cell to remove.
     */
    removeCell(state: any, payload: any): void {
      const shift =
        state.rosters[payload.rosterIndex].days[payload.dayIndex].shifts[
          payload.cellIndex
        ];

      /**
       * Setting the shift status to '2'.
       * The ui will filter out all the shift with statuses = [2] while rendering.
       */
      shift.statuses = [2];
    },

    /**
     * Revert any changes made during cell editing by restoring the 'rosterDay' from the backup.
     * @param {any} state - The Vuex state object.
     */
    cancel(state: any): void {
      if (state.editingRosterIndex >= 0 && state.editingDayIndex >= 0) {
        state.rosters[state.editingRosterIndex].days[state.editingDayIndex] =
          state.rosterDayBackup;
      }
    },

    /**
     * Assign or recalculate shift status by comparing with backup data before making the API call
     * @param {any} state - The Vuex state object.
     */
    assignStatuses(state: any): void {
      const shift =
        state.rosters[state.editingRosterIndex].days[state.editingDayIndex]
          .shifts[state.editingCellIndex];

      const shiftBackup = state.rosterDayBackup.shifts[state.editingCellIndex];

      /**
       * Statuses: 0 - No Change, 1 - Created, 2 - Deleted, 3 - Time Changed, 4 - Employees Changed
       * Checking if the shift is newly added (statuses=[1]) or deleted (statuses=[2]).
       * If newly added or deleted no need to recalculate statuses as these two status overrule the other statuses.
       */

      if (!shift.statuses.includes(1) && !shift.statuses.includes(2)) {
        /**
         * Iterate through the assignees to detect if assigned employees has changed.
         * Checking if assignee was newly added (status=1) or deleted (status=2).
         * If newly added or deleted indicating the assignees were changed so update the shift status to "statuses = [4]" (Employees Changed).
         * Otherwise, no change.
         */
        const isEmployeesChanged = shift.assignedEmployees.some(
          (employee: any) => {
            return employee.status != 0;
          }
        );

        if (isEmployeesChanged) {
          shift.statuses = [4];
        }

        /**
         * Comparing the current shift time with the backup shift time.
         * If time wasn't changed, no need to change anything.
         * If time was changed, then push the "Time Changed" status into the statuses ("statuses.push(3)").
         * Make sure to push it instead assigning it. Because there could be multiple statuses in the array.
         * Filter out the "0 - No Change" from the statuses
         */
        const isShiftTimeChanged = () => {
          const currentStartTime = JSON.stringify(shift.startTime);
          const currentEndTime = JSON.stringify(shift.endTime);

          const oldStartTime = JSON.stringify(shiftBackup.startTime);
          const oldEndTime = JSON.stringify(shiftBackup.endTime);

          return (
            currentStartTime != oldStartTime || currentEndTime != oldEndTime
          );
        };
        if (isShiftTimeChanged()) {
          shift.statuses = shift.statuses?.filter((item: number) => item != 0);
          shift.statuses?.push(3);
        }
      }
    },
  },

  actions: {
    async saveRosterCell(context: any, payload: any) {
      await context.commit("assignStatuses");
      /**
       * In case of new row or cloning roster cell "save" button will save the cell locally.
       * Otherwise, it will make API call.
       */
      if (context.state.isCreatingOrCloningFlag == -1) {
        console.log("Cell data saved (API Called). Payload:");
        console.log(context.state.rosterDay);
      } else {
        console.log("Cell data saved locally");
      }
      await context.commit("resetFlags");
    },

    async saveRosterDay(context: any) {
      console.log("Roster day saved (API Called). Payload:");
      console.log(context.state.rosterDay);
      await context.commit("resetFlags", true);
    },
  },
};
