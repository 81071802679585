export default {
  state: {
    filters: [
      {
        properties: {
          key: "",
          dataType: "",
          dropDownOptions: [],
        },
        query: "",
      },
    ],
    generatedQuery: "",
  },
  getters: {
    filters(state: any) {
      return state.filters;
    },

    query(state: any) {
      return state.generatedQuery;
    },
  },
  mutations: {
    addFilters(state: any) {
      state.filters.push({
        properties: {
          key: "",
          dataType: "",
          dropDownOptions: [],
        },
        query: "",
      });
    },

    removeFilter(state: any, payload: number) {
      state.filters.splice(payload, 1);
    },

    generateQuery(state: any) {
      state.generatedQuery = "";
      state.filters.forEach((filter: any) => {
        if (filter.properties.key) {
          state.generatedQuery =
            state.generatedQuery +
            filter.properties.key +
            "=" +
            filter.query +
            "&";
        }
      });
    },

    preAddFilters(state: any, payload: any) {
      state.filters = payload;
    },

    clearFilters(state: any) {
      state.filters = [
        {
          properties: {
            key: "",
            dataType: "",
            dropDownOptions: [],
          },
          query: "",
        },
      ];
      state.generatedQuery = "";
    },
  },
  actions: {
    addFilters(context: any, payload: any) {
      context.commit("addFilters", payload);
    },

    removeFilter(context: any, payload: any) {
      context.commit("removeFilter", payload);
    },

    clearFilters(context: any, payload: any) {
      context.commit("clearFilters", payload);
    },

    preAddFilters(context: any, payload: any) {
      context.commit("preAddFilters", payload);
      context.commit("generateQuery");
    },
  },
};
