import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/css/theme-layout.scss";

import { FontAwesomeIcon } from "./plugins/font-awesome";
import LoadingComponent from "./components/LoadingComponent.vue";
import CardRow from "./components/CardRow.vue";
import InputField from "./components/InputField.vue";
import TitleComponent from "./components/TitleComponent.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { loading } from "@/services/directives";

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        return "service worker running";
      })
      .catch((error) => {
        return "service worker stopped";
      });
  });
}

createApp(App)
  .use(store)
  .directive("loading", loading)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("loading-component", LoadingComponent)
  .component("card-row", CardRow)
  .component("input-field", InputField)
  .component("page-title", TitleComponent)
  .mount("#app-main");
