<template>
  <div
    :class="`${
      flex ? '' : 'min-h'
    } d-flex align-items-center justify-content-center`"
  >
    <div
      class="spinner-grow spinner-grow-sm text-secondary"
      role="status"
      style="width: 1.3rem; height: 1.3rem"
    >
      <span class="sr-only">Loading...</span>
    </div>
    <div
      class="spinner-grow spinner-grow-sm text-dark mx-2"
      role="status"
      style="width: 1.3rem; height: 1.3rem"
    >
      <span class="sr-only"></span>
    </div>
    <div
      class="spinner-grow spinner-grow-sm text-secondary"
      role="status"
      style="width: 1.3rem; height: 1.3rem"
    >
      <span class="sr-only"></span>
    </div>
    <div
      class="spinner-grow spinner-grow-sm text-dark ml-2"
      role="status"
      style="width: 1.3rem; height: 1.3rem"
    >
      <span class="sr-only"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingComponent",
  props: {
    flex: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.min-h {
  min-height: 60vh;
}
</style>
