<template>
  <div :class="`card bg-light shadow-sm ${margin}`">
    <div class="card-body py-3 border-0">
      <div class="row align-items-center">
        <div class="col-md-6 col-sm-12">
          <slot name="title"></slot>
        </div>

        <!--      Btn-->
        <div class="col-md-6 col-sm-12 mt-2 mt-md-0">
          <div class="d-flex justify-content-md-end">
            <slot name="button"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleComponent",
  props: {
    margin: {
      type: String,
      default: "mb-3",
    },
  },
};
</script>

<style scoped></style>
