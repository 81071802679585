import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faTrash,
  faPencilAlt,
  faArrowRight,
  faArrowLeft,
  faTimesCircle,
  faCheckCircle,
  faUpload,
  faPlusCircle,
  faExclamationCircle,
  faCopy,
  faEllipsisV,
  faFilter,
  faPrint,
  faHistory,
  faAngleRight,
  faAngleLeft,
  faDownload,
  faCalendarAlt,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faStepForward,
  faStepBackward,
  faExclamationTriangle,
  faInfoCircle,
  faVenusMars,
  faIdCard,
  faTimes,
  faBuilding,
  faPhoneAlt,
  faAt,
  faExclamation,
  faCalendarCheck,
  faAddressBook,
  faGlobe,
  faMapPin,
  faClock,
  faCogs,
  faPowerOff,
  faKey,
  faBan,
  faBullseye,
  faClipboard,
  faEnvelopeOpenText,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faBell,
  faHashtag,
  faMinusCircle,
  faClone,
  faPaste,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowLeft,
  faArrowRight,
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faTrash,
  faPencilAlt,
  faTimesCircle,
  faCheckCircle,
  faUpload,
  faPlusCircle,
  faExclamationCircle,
  faCopy,
  faEllipsisV,
  faFilter,
  faPrint,
  faHistory,
  faAngleRight,
  faAngleLeft,
  faDownload,
  faCalendarAlt,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faStepForward,
  faStepBackward,
  faExclamationTriangle,
  faInfoCircle,
  faVenusMars,
  faIdCard,
  faTimes,
  faBuilding,
  faPhoneAlt,
  faAt,
  faExclamation,
  faCalendarCheck,
  faAddressBook,
  faGlobe,
  faMapPin,
  faClock,
  faCogs,
  faPowerOff,
  faKey,
  faBan,
  faBullseye,
  faClipboard,
  faEnvelopeOpenText,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faBell,
  faSortAmountDownAlt,
  faHashtag,
  faMinusCircle,
  faClone,
  faPaste,
  faCheck
);

export { FontAwesomeIcon };
