const loading = {
  updated: (el: any, binding: any) => {
    if (binding.value === true) {
      const loadingElm = `<div class="inner-loading-backdrop" id="loadingElm">
        <div class="">
          <div class="spinner-grow text-dark" role="status">
          </div>
          <div class="spinner-grow text-bg-secondary mx-2" role="status">
          </div>
          <div class="spinner-grow text-light" role="status">
          </div>
        </div>
      </div>`;
      el.innerHTML += loadingElm;
    } else if (
      el.querySelector("#loadingElm") !== null &&
      binding.value === false
    ) {
      el.removeChild(el.lastChild);
    }
  },
};

const disabled = {
  updated: (el: any, binding: any) => {
    if (binding.value === true) {
      el.classList.add("disabled-section");
    } else {
      el.classList.remove("disabled-section");
    }
  },
};

export { loading, disabled };
